<template>
    <div class="toast-container">
        <v-alert :class="'v-toast-info ' + toast.customClass" :key="'toast_' + toastIndex" close-icon="mdi-close" closable type="info" v-for="(toast, toastIndex) in store.toastMessages">{{ toast.message }}</v-alert>
    </div>
</template>

<script>
import { useStore } from '@/store/index';

export default {
    computed: {
        store() {
            return useStore();
        },
    }
}
</script>

<style scoped lang="scss">
.toast-container {
    position: fixed;
    left: 48px;
    right: 48px;
    bottom: 65px;
    z-index: 10100;

    .v-toast-info {
        /* floating nodeupToast message */
        font-size: 14px;
        color: #000 !important;
        border: 1px solid #00aaff !important;
        background-color: #e6f7ff !important;
        box-shadow: 0px 3px 6px rgba(227, 230, 233, 0.646173);
        border-radius: 4px;

        :deep(.mdi-information):before {
            padding-left: 10px;
            color: #00aaff !important;
        }

        :deep(.v-alert__close) {
            .v-btn--icon.v-btn--density-default {
                /* reduce height to avoid forcing height to parent: 60px - 2x16px paddings */
                height: 28px;

                .mdi-close::before {
                    color: #66686d !important;
                }
            }
        }
    }
}



.fade-out {
    animation: fadeOut 1s;
    -webkit-animation: fadeOut 1s;
    -moz-animation: fadeOut 1s;
    -o-animation: fadeOut 1s;
    -ms-animation: fadeOut 1s;
    opacity: 0;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* mobile view */
@media (max-width: 820px) {
    .toast-container {
        left: 16px;
        right: 16px;
        bottom: 65px;
    }
}
</style>
